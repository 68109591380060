import Vue from 'vue'
import Router from 'vue-router'

const Dashboard = () => import('@/views/modules/dashboard/_Dashboard')
const Calendar = () => import('@/views/modules/calendar/Calendar')
const Course = () => import('@/views/modules/course/Course')
const Job = () => import('@/views/modules/shared/JobList')

// adm
const Register = () => import('@/views/modules/adm/Register')
const Login = () => import('@/views/modules/adm/Login')
const LoginTemp = () => import('@/views/modules/adm/LoginTemp')
const ResetPassword = () => import('@/views/modules/adm/ResetPassword')
const ConfirmMailMobile = () => import('@/views/modules/adm/ConfirmMailMobile')
const Plan = () => import('@/views/modules/adm/Plan')
const Store = () => import('@/views/modules/adm/Store')

const DocumentList = () => import('@/views/modules/adm/DocumentList')
const DocumentCreateUpdate = () => import('@/views/modules/adm/DocumentCreateUpdate')
const DocumentPublic = () => import('@/views/modules/adm/DocumentPublic')
const DocumentPreviewPublic = () => import('@/views/modules/adm/DocumentPreviewPublic')
const Company = () => import('@/views/modules/adm/Company')
const Parameter = () => import('@/views/modules/adm/Parameter')
const UserList = () => import('@/views/modules/adm/UserList')
const UserCreateUpdate = () => import('@/views/modules/adm/UserCreateUpdate')
const ReportAdm = () => import('@/views/modules/adm/ReportAdm')
const ReportAdmAudit = () => import('@/views/modules/adm/ReportAudit')

// crm
const CustomerList = () => import('@/views/modules/crm/CustomerList')
const CustomerCreateUpdate = () => import('@/views/modules/crm/CustomerCreateUpdate')
const ConfigCrm = () => import('@/views/modules/crm/ConfigCrm')
const CategoryCustomer = () => import('@/views/modules/crm/CategoryCustomer')
const ImportCustomer = () => import('@/views/modules/crm/ImportCustomer')
const ReportCrm = () => import('@/views/modules/crm/ReportCrm')
const ReportCrmCustomer = () => import('@/views/modules/crm/ReportCustomer')

// billing
const InvoiceList = () => import('@/views/modules/billing/InvoiceList')
const ToProfitList = () => import('@/views/modules/billing/ToProfitList')
const InvoiceCreateUpdate = () => import('@/views/modules/billing/InvoiceCreateUpdate')
const GenerateBilling = () => import('@/views/modules/billing/GenerateBilling')
const ReportBilling = () => import('@/views/modules/billing/ReportBilling')
const ReportInvoice = () => import('@/views/modules/billing/ReportInvoice')
const BilletList = () => import('@/views/modules/billing/BilletList')
const BilletConfigList = () => import('@/views/modules/billing/BilletConfigList')
const ShipmentReturnList = () => import('@/views/modules/billing/ShipmentReturnList')
const ReportBillet = () => import('@/views/modules/billing/ReportBillet')
const _ReportBillet = () => import('@/views/modules/billing/_ReportBillet')

// humanResources
const CollaboratorList = () => import('@/views/modules/human-resources/CollaboratorList')
const CollaboratorCreateUpdate = () => import('@/views/modules/human-resources/CollaboratorCreateUpdate')

// supplier
const SupplierList = () => import('@/views/modules/supplier/SupplierList')
const SupplierCreateUpdate = () => import('@/views/modules/supplier/SupplierCreateUpdate')
const SupplierOrderList = () => import('@/views/modules/supplier/SupplierOrderList')
const SupplierOrderCreateUpdate = () => import('@/views/modules/supplier/SupplierOrderCreateUpdate')

// rental
const ConfigRental = () => import('@/views/modules/rental/ConfigRental')
const RentalList = () => import('@/views/modules/rental/RentalList')
const RentSelectOption = () => import('@/views/modules/rental/RentSelectOption')
const RentCreateUpdate = () => import('@/views/modules/rental/RentCreateUpdate')
const KitRentCreateUpdate = () => import('@/views/modules/rental/KitRentCreateUpdate')
const ReportRental = () => import('@/views/modules/rental/ReportRental')
const ReportRentByCustomer = () => import('@/views/modules/rental/ReportRentByCustomer')
const ReportRentByPeriodRent = () => import('@/views/modules/rental/ReportRentByPeriodRent')
const ReportRentByPayment = () => import('@/views/modules/rental/ReportRentByPayment')
const ReportRentByProduct = () => import('@/views/modules/rental/ReportRentByProduct')
const ReportRentPerformance = () => import('@/views/modules/rental/ReportRentPerformance')

// pdv
const PdvRentSelectOption = () => import('@/views/modules/pdv/PdvRentSelectOption')
const PdvSearch = () => import('@/views/modules/pdv/PdvSearch')
const PdvRentList = () => import('@/views/modules/pdv/PdvRentList')
const PdvRentCreateUpdate = () => import('@/views/modules/pdv/PdvRentCreateUpdate')
const SuccessPdvRent = () => import('@/views/modules/pdv/SuccessPdvRent')

// cashier
const CashierList = () => import('@/views/modules/cashier/CashierList')

// stock
const ProductList = () => import('@/views/modules/stock/ProductList')
const ProductSelectOption = () => import('@/views/modules/stock/ProductSelectOption')
const ProductCreateUpdate = () => import('@/views/modules/stock/ProductCreateUpdate')
const ImportProduct = () => import('@/views/modules/stock/ImportProduct')
const ConfigStock = () => import('@/views/modules/stock/ConfigStock')
const ReportStock = () => import('@/views/modules/stock/ReportStock')
const ReportStockProduct = () => import('@/views/modules/stock/ReportProduct')
const ReportStockProductByCustomer = () => import('@/views/modules/stock/ReportProductByCustomer')
const ReportStockPatrimony = () => import('@/views/modules/stock/ReportPatrimony')
const ReportStockHistoryPatrimony = () => import('@/views/modules/stock/ReportHistoryPatrimony')

const GenerateOutput = () => import('@/views/modules/stock/GenerateOutput')
const GenerateReturn = () => import('@/views/modules/stock/GenerateReturn')
const GenerateRenewed = () => import('@/views/modules/stock/GenerateRenewed')

//finance
const ConfigFinance = () => import('@/views/modules/finance/ConfigFinance')
const BankReconciliation = () => import('@/views/modules/finance/BankReconciliation')
const BankAccountCreateUpdate = () => import('@/views/modules/finance/BankAccountCreateUpdate')
const PayableReceivableList = () => import('@/views/modules/finance/PayableReceivableList')
const PayableReceivableCreateUpdate = () => import('@/views/modules/finance/PayableReceivableCreateUpdate')
const TransferAccountList = () => import('@/views/modules/finance/TransferAccountList')
const ReportFinance = () => import('@/views/modules/finance/ReportFinance')
const ReportPayableReceivable = () => import('@/views/modules/finance/ReportPayableReceivable')

// operational
const OsPanel = () => import('@/views/modules/operational/OsPanel')
const ReservationPanel = () => import('@/views/modules/operational/ReservationPanel')
const OperationlSearch = () => import('@/views/modules/operational/OperationlSearch')
const RentOperationalCreateUpdate = () => import('@/views/modules/operational/RentOperationalCreateUpdate')
const RentOperationalList = () => import('@/views/modules/operational/RentOperationalList')
const ReportOperational = () => import('@/views/modules/operational/ReportOperational')
const ReportReportDeliveryDevolution = () => import('@/views/modules/operational/ReportDeliveryDevolution')
const ReplacementPatrimony = () => import('@/views/modules/operational/ReplacementPatrimony')
const MovementBulkList = () => import('@/views/modules/operational/MovementBulkList')
const MovementBulkUpdate = () => import('@/views/modules/operational/MovementBulkUpdate')

// taxDocument
const ConfigTaxDocument = () => import('@/views/modules/tax-document/ConfigTaxDocument')

// manutenção
const OrderServiceList = () => import('@/views/modules/maintenance/OrderServiceList')
const OrderServiceCreateUpdate = () => import('@/views/modules/maintenance/OrderServiceCreateUpdate')
const OsPanelMaintenance = () => import('@/views/modules/maintenance/OsPanel')
const ReportMaintenance = () => import('@/views/modules/maintenance/ReportMaintenance')
const ReportOrderService = () => import('@/views/modules/maintenance/ReportOrderService')
const ReportProductOrderService = () => import('@/views/modules/maintenance/ReportProductOrderService')

// planner
const ConfigPlanner = () => import('@/views/modules/planner/ConfigPlanner')
const FunnelList = () => import('@/views/modules/planner/FunnelList')
const Kanban = () => import('@/views/modules/planner/Kanban')

// nfse
const NfseList = () => import('@/views/modules/tax-document/NfseList')
const NfseCreateUpdate = () => import('@/views/modules/tax-document/NfseCreateUpdate')

// nfe
const NfeList = () => import('@/views/modules/tax-document/NfeList')
const NfeCreateUpdate = () => import('@/views/modules/tax-document/NfeCreateUpdate')
const NfeReceivedList = () => import('@/views/modules/tax-document/NfeReceivedList')

// signature
const SignatureList = () => import('@/views/modules/signature/SignatureList')
const ConfigSignature = () => import('@/views/modules/signature/ConfigSignature')

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', redirect: { name: "login" } },
        { path: "/register", name: "register", component: Register },
        { path: "/document/:nameFileTemplate/:nameFileParameter", name: "documentPublic", props: { module: "Document" }, component: DocumentPublic },
        { path: "/document/preview/:documentId/:billetId/:logo", name: "documentPreviewPublic", props: { module: "Document" }, component: DocumentPreviewPublic },
        { path: "/login", name: "login", component: Login },
        { path: "/login-temp/:code/:companyId", name: "loginTemp", component: LoginTemp },
        { path: "/reset-password/:code", name: "resetPassword", component: ResetPassword },
        { path: "/confirm-mail/:code", name: "confirmMail", component: ConfirmMailMobile },
        { path: "/confirm-mobile/:code", name: "confimMobile", component: ConfirmMailMobile },
        { path: "/calendar", name: "calendar", props: { module: "Calendar" }, component: Calendar },
        { path: "/dashboard", name: "dashboard", props: { module: "Dashboard" }, component: Dashboard },
        { path: "/job", name: "jobList", props: { module: "Job" }, component: Job },
        { path: "/job/:filter", name: "jobListFilter", props: { module: "Job" }, component: Job },
        { path: "/course", name: "course", props: { module: "Course" }, component: Course },
        { path: "/adm/document/list", name: "documentList", props: { module: "Adm" }, component: DocumentList },
        { path: "/adm/document", name: "documentCreate", props: { module: "Adm" }, component: DocumentCreateUpdate },
        { path: "/adm/document/:id", name: "documentUpdate", props: { module: "Adm" }, component: DocumentCreateUpdate },
        { path: "/adm/company", name: "company", props: { module: "Adm" }, component: Company },
        { path: "/adm/parameter", name: "parameter", props: { module: "Adm" }, component: Parameter },
        { path: "/adm/user/list", name: "userList", props: { module: "Adm" }, component: UserList },
        { path: "/adm/user", name: "userCreate", props: { module: "Adm" }, component: UserCreateUpdate },
        { path: "/adm/user/:id", name: "userUpdate", props: { module: "Adm" }, component: UserCreateUpdate },
        { path: "/adm/report", name: "reportAdm", props: { module: "Adm" }, component: ReportAdm },
        { path: "/adm/report/audit", name: "reportAdmAudit", props: { module: "Adm", report: "Relatório de Auditoria" }, component: ReportAdmAudit },
        { path: "/adm/report/:id/:name", name: "reportAdmAuditCustom", props: { module: "Adm", report: "Relatório de Auditoria" }, component: ReportAdmAudit },
        { path: "/adm/plan", name: "plan", props: { module: "Adm" }, component: Plan },
        { path: "/adm/store", name: "store", props: { module: "Adm" }, component: Store },
        { path: "/billing/invoice/to-profit/list", name: "toProfitList", props: { module: "Billing" }, component: ToProfitList },
        { path: "/billing/invoice/to-profit/list/:status", name: "toProfitListFilter", props: { module: "Billing" }, component: ToProfitList },
        { path: "/billing/invoice/list", name: "invoiceList", props: { module: "Billing" }, component: InvoiceList },
        { path: "/billing/invoice", name: "invoiceCreate", props: { module: "Billing" }, component: InvoiceCreateUpdate },
        { path: "/billing/invoice/:id", name: "invoiceUpdate", props: { module: "Billing" }, component: InvoiceCreateUpdate },
        { path: "/billing/generate-billing/:rentId/:customerId", name: "generateBilling", props: { module: "Rental" }, component: GenerateBilling },
        { path: "/billing/report", name: "reportBilling", props: { module: "Billing" }, component: ReportBilling },
        { path: "/billing/report/invoice", name: "reportInvoice", props: { module: "Billing", report: "Relatório de Fatura" }, component: ReportInvoice },
        { path: "/billing/report/:id/:name", name: "reportInvoiceCustom", props: { module: "Billing", report: "Relatório de Fatura" }, component: ReportInvoice },
        { path: "/billing/billet/report", name: "reportBillet", props: { module: "Billet" }, component: ReportBillet },
        { path: "/billing/report/billet", name: "_reportBillet", props: { module: "Billet", report: "Relatório de Boleto" }, component: _ReportBillet },
        { path: "/billing/billet/list", name: "billetList", props: { module: "Billet" }, component: BilletList },
        { path: "/billing/billet/list/:filter/:status", name: "billetListFilter", props: { module: "Billet" }, component: BilletList },
        { path: "/billing/billet-config/list", name: "billetConfigList", props: { module: "Billet" }, component: BilletConfigList },
        { path: "/billing/shipment-return/list", name: "shipmentReturnList", props: { module: "Billet" }, component: ShipmentReturnList },
        { path: "/crm/customer/list", name: "customerList", props: { module: "CRM" }, component: CustomerList },
        { path: "/crm/customer", name: "customerCreate", props: { module: "CRM" }, component: CustomerCreateUpdate },
        { path: "/crm/customer/:id", name: "customerUpdate", props: { module: "CRM" }, component: CustomerCreateUpdate },
        { path: "/crm/import", name: "importCustomer", props: { module: "CRM" }, component: ImportCustomer },
        { path: "/crm/config", name: "configCrm", props: { module: "CRM" }, component: ConfigCrm },
        { path: "/crm/category-customer", name: "categoryCustomer", props: { module: "CRM" }, component: CategoryCustomer },
        { path: "/crm/report", name: "reportCrm", props: { module: "CRM" }, component: ReportCrm },
        { path: "/crm/report/customer", name: "reportCrmCustomer", props: { module: "CRM", report: "Relatório de Cliente" }, component: ReportCrmCustomer },
        { path: "/crm/report/customer/:id/:name", name: "reportCrmCustomerCustom", props: { module: "CRM", report: "Relatório de Cliente" }, component: ReportCrmCustomer },
        { path: "/human-resources/collaborator/list", name: "collaboratorList", props: { module: "HumanResources" }, component: CollaboratorList },
        { path: "/human-resources/collaborator", name: "collaboratorCreate", props: { module: "HumanResources" }, component: CollaboratorCreateUpdate },
        { path: "/human-resources/collaborator/:id", name: "collaboratorUpdate", props: { module: "HumanResources" }, component: CollaboratorCreateUpdate },
        { path: "/maintenance/report", name: "reportMaintenance", props: { module: "Maintenance" }, component: ReportMaintenance },
        { path: "/maintenance/report/order-service", name: "reportOrderService", props: { module: "Maintenance" }, component: ReportOrderService },
        { path: "/maintenance/report/product-order-service", name: "reportProductOrderService", props: { module: "Maintenance" }, component: ReportProductOrderService },
        { path: "/maintenance/os-service/list", name: "maintenanceList", props: { module: "Maintenance" }, component: OrderServiceList },
        { path: "/maintenance/os-service", name: "orderServiceCreate", props: { module: "Maintenance" }, component: OrderServiceCreateUpdate },
        { path: "/maintenance/os-service/:id", name: "orderServiceUpdate", props: { module: "Maintenance" }, component: OrderServiceCreateUpdate },
        { path: "/maintenance/os-panel", name: "osPanelMaintenanceList", props: { module: "Maintenance" }, component: OsPanelMaintenance },
        { path: "/operational/rent-operational/list", name: "rentOperationalList", props: { module: "Operational" }, component: RentOperationalList },
        { path: "/operational/rent", name: "rentOperationalCreate", props: { module: "Operational" }, component: RentOperationalCreateUpdate },
        { path: "/operational/rent/:id/:periodRentId", name: "rentOperationalUpdate", props: { module: "Operational" }, component: RentOperationalCreateUpdate },
        { path: "/operational/reservation-panel", name: "reservationPanel", props: { module: "Operational" }, component: ReservationPanel },
        { path: "/operational/operational-search", name: "operationalSearch", props: { module: "Operational" }, component: OperationlSearch },
        { path: "/operational/replacement-patrimony", name: "replacementPatrimony", props: { module: "Moviment" }, component: ReplacementPatrimony },
        { path: "/operational/movement-bulk/list", name: "movementBulkList", props: { module: "Operational" }, component: MovementBulkList },
        { path: "/operational/movement-bulk/update/:id", name: "movementBulkUpdate", props: { module: "Operational" }, component: MovementBulkUpdate }, 
        { path: "/operational/os-panel", name: "osPanelList", props: { module: "Operational" }, component: OsPanel },
        { path: "/operational/os-panel/:filter", name: "osPanelListFilter", props: { module: "Operational" }, component: OsPanel },
        { path: "/operational/report", name: "reportOperational", props: { module: "Operational" }, component: ReportOperational },
        { path: "/operational/report/delivery-devolution", name: "reportDeliveryDevolution", props: { module: "Operational", report: "Saídas e Retornos" }, component: ReportReportDeliveryDevolution },
        { path: "/operational/report/delivery-devolution/:id/:name", name: "reportDeliveryDevolutionCustom", props: { module: "Operational", report: "Saídas e Retornos" }, component: ReportReportDeliveryDevolution },
        { path: "/cashier/list", name: "cashierList", props: { module: "Cashier" }, component: CashierList },
        { path: "/supplier/supplier-order/list", name: "supplierOrderList", props: { module: "Supplier" }, component: SupplierOrderList },
        { path: "/supplier/list", name: "supplierList", props: { module: "Supplier" }, component: SupplierList },
        { path: "/supplier", name: "supplierCreate", props: { module: "Supplier" }, component: SupplierCreateUpdate },
        { path: "/supplier/:id", name: "supplierUpdate", props: { module: "Supplier" }, component: SupplierCreateUpdate },
        { path: "/supplier-order", name: "supplierOrderCreate", props: { module: "Supplier" }, component: SupplierOrderCreateUpdate },
        { path: "/supplier-order/:id", name: "supplierOrderUpdate", props: { module: "Supplier" }, component: SupplierOrderCreateUpdate },
        { path: "/planner/config", name: "configPlanner", props: { module: "Planner" }, component: ConfigPlanner },
        { path: "/planner/kanban/:id/:name", name: "kanbanList", props: { module: "Planner" }, component: Kanban },
        { path: "/planner/funnel/list", name: "funnelList", props: { module: "Planner" }, component: FunnelList },
        { path: "/pdv/rent", name: "pdvRentSelectOption", props: { module: "PDV" }, component: PdvRentSelectOption },
        { path: "/pdv/rental/search", name: "pdvSearch", props: { module: "PDV" }, component: PdvSearch },
        { path: "/pdv/rental/list", name: "pdvRentList", props: { module: "PDV" }, component: PdvRentList },
        { path: "/pdv/rental", name: "pdvRentCreate", props: { module: "PDV" }, component: PdvRentCreateUpdate },
        { path: "/pdv/rental/:id", name: "pdvRentUpdate", props: { module: "PDV" }, component: PdvRentCreateUpdate },
        { path: "/pdv/rental/success/:rentId", name: "successPdvRent", props: { module: "PDV" }, component: SuccessPdvRent },
        { path: "/rental/config", name: "configRental", props: { module: "Rental" }, component: ConfigRental },
        { path: "/rental/rental/list", name: "rentalList", props: { module: "Rental" }, component: RentalList },
        { path: "/rental/rent", name: "rentSelectOption", props: { module: "Rental" }, component: RentSelectOption },
        { path: "/rental/rent/:type", name: "rentCreate", props: { module: "Rental" }, component: RentCreateUpdate },
        { path: "/rental/rent/:type/:id", name: "rentUpdate", props: { module: "Rental" }, component: RentCreateUpdate },
        { path: "/rental/kit-rent/", name: "kitRentCreate", props: { module: "Stock" }, component: KitRentCreateUpdate },
        { path: "/rental/kit-rent/:id", name: "kitRentUpdate", props: { module: "Stock" }, component: KitRentCreateUpdate },
        { path: "/rental/report", name: "reportRental", props: { module: "Rental" }, component: ReportRental },
        { path: "/rental/report/rent-by-customer", name: "reportRentByCustomer", props: { module: "Rental", report: "Locação por Cliente" }, component: ReportRentByCustomer },
        { path: "/rental/report/rent-by-customer/:id/:name", name: "reportRentByCustomerCustom", props: { module: "Rental", report: "Locação por Cliente" }, component: ReportRentByCustomer },
        { path: "/rental/report/rent-by-period-rent", name: "reportRentByPeriodRent", props: { module: "Rental", report: "Locação por Período de Locação" }, component: ReportRentByPeriodRent },
        { path: "/rental/report/rent-by-period-rent/:id/:name", name: "reportRentByPeriodRentCustom", props: { module: "Rental", report: "Locação por Período de Locação" }, component: ReportRentByPeriodRent },
        { path: "/rental/report/rent-by-payment", name: "reportRentByPayment", props: { module: "Rental", report: "Locação por Pagamento" }, component: ReportRentByPayment },
        { path: "/rental/report/rent-by-payment/:id/:name", name: "reportRentByPaymentCustom", props: { module: "Rental", report: "Locação por Pagamento" }, component: ReportRentByPayment },
        { path: "/rental/report/rent-by-product", name: "reportRentByProduct", props: { module: "Rental", report: "Locação por Produto" }, component: ReportRentByProduct },
        { path: "/rental/report/rent-by-product/:id/:name", name: "reportRentByProductCustom", props: { module: "Rental", report: "Locação por Produto" }, component: ReportRentByProduct },
        { path: "/rental/report/rent-by-performance", name: "reportRentPerformance", props: { module: "Finance", report: "Receita x Despesa de Locação" }, component: ReportRentPerformance },
        { path: "/rental/report/rent-by-performance/:id/:name", name: "reportRentPerformanceCustom", props: { module: "Finance", report: "Receita x Despesa de Locação" }, component: ReportRentPerformance },
        { path: "/finance/config", name: "configFinance", props: { module: "Finance" }, component: ConfigFinance },
        { path: "/finance/bank-account", name: "bankAccountCreate", props: { module: "Finance" }, component: BankAccountCreateUpdate },
        { path: "/finance/bank-account/:id", name: "bankAccountUpdate", props: { module: "Finance" }, component: BankAccountCreateUpdate },
        { path: "/finance/bank-reconciliation", name: "bankReconciliation", props: { module: "Finance" }, component: BankReconciliation },
        { path: "/finance/payable-receivable", name: "payableReceivableList", props: { module: "Finance" }, component: PayableReceivableList },
        { path: "/finance/payable-receivable", name: "payableReceivableCreate", props: { module: "Finance" }, component: PayableReceivableCreateUpdate },
        { path: "/finance/payable-receivable/:id", name: "payableReceivableUpdate", props: { module: "Finance" }, component: PayableReceivableCreateUpdate },
        { path: "/finance/transfer-account", name: "transferAccountList", props: { module: "Finance" }, component: TransferAccountList },
        { path: "/finance/report", name: "reportFinance", props: { module: "Finance" }, component: ReportFinance },
        { path: "/finance/report/payable-receivable", name: "reportPayableReceivable", props: { module: "Finance", report: "Pagar e Receber" }, component: ReportPayableReceivable },
        { path: "/finance/report/payable-receivable/:id/:name", name: "reportPayableReceivableCustom", props: { module: "Finance", report: "Pagar e Receber" }, component: ReportPayableReceivable },
        { path: "/stock/product/list", name: "productList", props: { module: "Stock" }, component: ProductList },
        { path: "/stock/product", name: "productSelectOption", props: { module: "Stock" }, component: ProductSelectOption },
        { path: "/stock/product/:type", name: "productCreate", props: { module: "Stock" }, component: ProductCreateUpdate },
        { path: "/stock/product/:type/:id", name: "productUpdate", props: { module: "Stock" }, component: ProductCreateUpdate },
        { path: "/stock/import", name: "importProduct", props: { module: "Stock" }, component: ImportProduct },
        { path: "/stock/config", name: "configStock", props: { module: "Stock" }, component: ConfigStock },
        { path: "/stock/report", name: "reportStock", props: { module: "Stock" }, component: ReportStock },
        { path: "/stock/report/product", name: "reportStockProduct", props: { module: "Stock", report: "Relatório de Produto" }, component: ReportStockProduct },
        { path: "/stock/report/product/:id/:name", name: "reportStockProductCustom", props: { module: "Stock", report: "Relatório de Produto" }, component: ReportStockProduct },
        { path: "/stock/report/product-by-customer", name: "reportStockProductByCustomer", props: { module: "Stock", report: "Relatório de Produto por Cliente" }, component: ReportStockProductByCustomer },
        { path: "/stock/report/product-by-customer/:id/:name", name: "reportStockProductByCustomerCustom", props: { module: "Stock", report: "Relatório de Produto por Cliente" }, component: ReportStockProductByCustomer },
        { path: "/stock/report/patrimony", name: "reportStockPatrimony", props: { module: "Stock", report: "Relatório de Patrimônio" }, component: ReportStockPatrimony },
        { path: "/stock/report/patrimony/:id/:name", name: "reportStockPatrimonyCustom", props: { module: "Stock", report: "Relatório de Patrimônio" }, component: ReportStockPatrimony },
        { path: "/stock/report/history/patrimony", name: "reportStockHistoryPatrimony", props: { module: "Stock", report: "Relatório Histórico de Patrimônio" }, component: ReportStockHistoryPatrimony },
        { path: "/stock/report/history/patrimony/:id/:name", name: "reportStockHistoryPatrimonyCustom", props: { module: "Stock", report: "Relatório Histórico de Patrimônio" }, component: ReportStockHistoryPatrimony },
        { path: "/stock/moviment/generate-output/:id/:periodRentId", name: "generateOutput", props: { module: "Moviment" }, component: GenerateOutput },
        { path: "/stock/moviment/generate-return/:id/:periodRentId", name: "generateReturn", props: { module: "Moviment" }, component: GenerateReturn },
        { path: "/stock/moviment/generate-renewed/:id/:periodRentId", name: "generateRenewed", props: { module: "Moviment" }, component: GenerateRenewed },
        { path: "/tax-document/config", name: "configTaxDocument", props: { module: "TaxDocument" }, component: ConfigTaxDocument },
        { path: "/tax-document/nfse/list", name: "nfseList", props: { module: "TaxDocument" }, component: NfseList },
        { path: "/tax-document/nfse", name: "nfseCreate", props: { module: "TaxDocument" }, component: NfseCreateUpdate },
        { path: "/tax-document/nfse/:id", name: "nfseUpdate", props: { module: "TaxDocument" }, component: NfseCreateUpdate },
        { path: "/tax-document/nfe/list", name: "nfeList", props: { module: "TaxDocument" }, component: NfeList },
        { path: "/tax-document/nfe", name: "nfeCreate", props: { module: "TaxDocument" }, component: NfeCreateUpdate },
        { path: "/tax-document/nfe-received/list", name: "nfeReceivedList", props: { module: "TaxDocument" }, component: NfeReceivedList },
        { path: "/tax-document/nfe/:id", name: "nfeUpdate", props: { module: "TaxDocument" }, component: NfeCreateUpdate },
        { path: "/signature/config", name: "configSignature", props: { module: "Signature" }, component: ConfigSignature },
        { path: "/signature/list/:status", name: "signatureList", props: { module: "Signature" }, component: SignatureList },

        { path: '*', component: Login }
    ],
})

export default router